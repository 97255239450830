import React, { Component } from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import one from '../../../img/1.jpeg'
import two from '../../../img/g2.jpg'
import three from '../../../img/3.jpeg'
import fr from '../../../img/g4.jpg'
import fv from '../../../img/g5.jpg'
import sx from '../../../img/g6.jpg'
import sv from '../../../img/7.jpeg'
import et from '../../../img/8.jpeg'
import nn from '../../../img/9.jpeg'
import tn from '../../../img/11.jpeg'
import el from '../../../img/12.jpeg'
import tw from '../../../img/13.jpeg'
import tt from '../../../img/g1.jpg'
import ft from '../../../img/g3.jpg'





let slide=[];
class Sliders extends Component {
//   componentDidMount() {
//     var req = require.context("../../../img", false, /.*\.jpg$/);
//     console.log(req)
//     req.keys().forEach(function(key){
        
//         req(key)
//         slide.push(req(key));
//      })
    
//  }
  render() {
    const settings = {
      infinite: true,
      autoplay:true,
      autoplaySpeed:5000,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrow:false
    };
    // let imag=[one,two,three,fr,fv,sx,sv,et,nn,tn,el,tw,tt,ft]
    let imag=[two,fr,fv,sx,tt,ft]

    return (
      <div className="slider">
        <div className="page-title"><h2>Gems Gemology Institute Jaipur</h2></div>
        <div className="slides">
          <Slider ref={c => (this.slider = c)} {...settings}>
            {imag.map((image,index)=><img src={image} key={index+'TrekSlider'} alt="Gems Images" />)}
          </Slider>
        </div>
        <div className="sell">
          <p>
            We sell the best quality and certified gems stones. 
          </p>
          <p>
            We also provide certification for every kind of stones.
          </p>
        </div>
      </div>
    )
  }
}

export default Sliders
