import axios from 'axios';

import {
  GET_GEM,
  GET_GEMS,
  GEM_LOADING,
  ADD_GEM,
  DELETE_GEM,
  GET_ERRORS,
  CLEAR_ERRORS,

} from './types';

// Get current trek
export const getGem = id => dispatch => {
  dispatch(setGemsLoading());
  axios
    .get(`https://www.newad.ggij.co.in/api/gems/${id}`)
    .then(res =>
      dispatch({
        type: GET_GEM,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_GEM,
        payload: {}
      })
    );
};


// Add Trek
export const addGems = newGems => dispatch => {
    dispatch(clearErrors());
    axios
      .post('https://www.newad.ggij.co.in/api/gems/add', newGems)
      // .post('http://slimapp/api/gems/add', newGems)
      .then(res =>{
        console.log(res)
        dispatch({
          type: ADD_GEM,
          payload: res.data
        })
      
      }
      )
      .catch(err =>{
        console.log(err)
        dispatch({
          type: GET_ERRORS,
          payload: err
        })
      }
      );
  };

// Edit Trek
export const editGems = (gemsId,newGems,history) => dispatch => {
  dispatch(clearErrors());
  axios
    .put(`https://www.newad.ggij.co.in/gems/update/${gemsId}`, newGems)
    .then(res => history.push('/admin/gems'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};

// Get all treks
export const getGems = () => dispatch => {
  dispatch(setGemsLoading());
  axios
    .get('https://www.newad.ggij.co.in/api/gems')
    .then(res =>
      dispatch({
        type: GET_GEMS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_GEMS,
        payload: null
      })
    );
};

// Delete Post
export const deleteGem = id => dispatch => {
    axios
      .delete(`https://www.newad.ggij.co.in/api/gems/delete/${id}`)
      .then(res =>
        dispatch({
          type: DELETE_GEM,
          payload: id
        })
      )
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err
        })
      );
  };

// Trek loading
export const setGemsLoading = () => {
  return {
    type: GEM_LOADING
  };
};

// Clear errors
export const clearErrors = () => {
    return {
      type: CLEAR_ERRORS
    };
  };
  
