// import React, { Component } from 'react'
import React, { useState, useEffect } from 'react';
import ReactDataGrid from "react-data-grid";
import { Toolbar, Data } from "react-data-grid-addons";
import ReactLoading from 'react-loading';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {getGems,deleteGem} from '../../actions/gemsActions';
import isEmpty from '../../validations/is-empty'

const selectors = Data.Selectors;
const defaultColumnProperties = {
  resizable: true,
  width: 120,
  filterable: true,
};
const ROW_COUNT = 50;


const handleFilterChange = filter => filters => {
  const newFilters = { ...filters };
  if (filter.filterTerm) {
    newFilters[filter.column.key] = filter;
    
  } else {
    delete newFilters[filter.column.key];
  }
  return newFilters;
};
function getRows(rows, filters) {
  return selectors.getRows({ rows, filters });
}



function Gems(props) {
  useEffect(() => {
    // code to run on component mount
    props.getGems()
    console.log(props)
  }, [])
  const onDelete = (id)=>{
    props.deleteGem(id)
    // props.history.push('/admin/gems')
  }
  const DeleteAction=({value})=>{return <a onClick={()=>onDelete(value)} className="btn-text btn-text-pink" >Delete</a>}
  const columns = [
    { key: 'no', name: 'Track Number' },
    { key: 'name', name: 'Name' },
    { key: 'weight', name: 'Weight' },
    { key: 'color', name: 'Color' },
    { key: 'shape', name: 'Shape' },
    { key: 'hardness', name: 'Hardness' },
    { key: 'origin', name: 'Origin' },
    { key: 'refractor_index', name: 'Refractor Index' },
    { key: 'photo', name: 'Image', },
    { key: 'id', name: 'Delete',formatter:DeleteAction },
  ].map(c => ({ ...c, ...defaultColumnProperties }));
  const {gems,loading}=props.gems;
  // console.log(gems)
  const [filters, setFilters] = useState({});
  const filteredRows = getRows(gems, filters);
  return (
    <div className="gems">
      {/* <h2>Gems</h2> */}
      {
        loading || gems===null?
        <div className="loading"><ReactLoading type="spin" color="#222" height={100} width={75} /></div>
        :
        <ReactDataGrid
          columns={columns}
          rowGetter={i => filteredRows[i]}
          rowsCount={filteredRows.length}
          minHeight={500}
          toolbar={<Toolbar enableFilter={true} />}
          onAddFilter={filter => setFilters(handleFilterChange(filter))}
          onClearFilters={() => setFilters({})}
          onColumnResize={(idx, width) =>
        console.log(`Column ${idx} has been resized to ${width}`)}
          minHeight={600} />
      }
      
    </div>
  )
}



Gems.propTypes ={
  getGems: PropTypes.func.isRequired,
  deleteGem:PropTypes.func.isRequired,
  gems:PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  gems:state.gems,
})


export default connect(mapStateToProps, {getGems,deleteGem})(Gems);

// export default Gems
