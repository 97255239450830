import React, { Component } from 'react'
import email from '../../../img/icon/email.png'
import phone from '../../../img/icon/phone2.png'
import location from '../../../img/icon/location.png'

class Contact extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       name:'',
       email:'',
       phone:'',
       message:''
    }
    this.onChange=this.onChange.bind(this);
    this.Clear=this.Clear.bind(this);
  }
  onChange(e){
    this.setState({[e.target.name]:e.target.value})
  }
  Clear(){
    this.setState({
      name:'',
      email:'',
      phone:'',
      message:''
   })
  }
  render() {
    return (
      <div className="contact">
        <div className="contact-left">
          <div className="contact-header">
            <h2>Contact Us For Lab Testing Gems</h2>
          </div>
          <div className="contact-main">
            <div className="contact-main__item">
              <div className="contact-icons">
                <img src={email} alt="GGIJ_EMAIL"/>
                
              </div>
              <div className="contact-name">
                  <span>info@ggij.co.in</span>
              </div>

            </div>
            <div className="contact-main__item">
              <div className="contact-icons">
              <img src={phone} alt="GGIJ_EMAIL"/>
                
              </div>
              <div className="contact-name">
                <span>+91 9887178611</span>
              </div>

            </div>
            <div className="contact-main__item">
              <div className="contact-icons">
              <img src={location} alt="GGIJ_EMAIL"/>
                
              </div>
              <div className="contact-name">
                  <span>3029 Gopal ji ka Rasta, Johri Bazar, Jaipur(Rajasthan)</span>
              </div>

            </div>
          </div>
          <div className="contact-footer">
            <p>www.ggij.co.in</p>
          </div>
        </div>
        <div className="contact-right">
          <div className="contact-header">
            <h2>Please fill up this form</h2>
          </div>
          <div className="contact-form">
            <div className="contact-form__group">
              <label htmlFor="name" className="contact-form__label">Name</label>
              <input onChange={this.onChange} value={this.state.name} required type="text" name="name" className="contact-form__input"/>
            </div>
            <div className="contact-form__group">
              <label htmlFor="email" className="contact-form__label">Email</label>
              <input onChange={this.onChange}
              value={this.state.email} 
              pattern="/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
               required type="email" name="email" className="contact-form__input"/>
            </div>
            <div className="contact-form__group">
              <label htmlFor="phone" className="contact-form__label">Phone</label>
              <input
              value={this.state.phone}  onChange={this.onChange} required type="number" name="phone" className="contact-form__input"/>
            </div>
            <div className="contact-form__group">
              <label htmlFor="message" className="contact-form__label">Message</label>
              <input value={this.state.message}  onChange={this.onChange} required type="text" name="message" className="contact-form__input"/>
            </div>
            
          </div>
          <div className="contact-form__submit">
              <a href="#q" onClick={()=>this.Clear()} className="btn-block ">Contact</a>
            </div>
        </div>

      </div>
    )
  }
}

export default Contact
