import {
  GET_GEM,
  GET_GEMS,
  GEM_LOADING,
  ADD_GEM,
  DELETE_GEM
} from '../actions/types';

const initialState = {
  gems: [],
  gem: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GEM_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_GEM:
      return {
        ...state,
        gem: action.payload,
        loading: false
      };
    case GET_GEMS:
      return {
        ...state,
        gems: action.payload,
        loading: false
      };
  case ADD_GEM:
      return {
        ...state,
        gems: [action.payload, ...state.gems]
      };
    case DELETE_GEM:
      return {
        ...state,
        gems:  state.gems.filter(gem => gem.id !== action.payload)
      };
    default:
      return state;
  }
}
