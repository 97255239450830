import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {addGems} from '../../actions/gemsActions';
import isEmpty from '../../validations/is-empty';
import TextFieldGroup from '../common/TextFieldGroup'

class AddGems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            no: '',
            name: '',
            weight: '',
            hardness: '',
            color: '',
            refractor_index:'',
            shape:'',
            origin:'',
            photo:'',
            message:'',
            errors: {}
          };
    
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
      }
      componentWillReceiveProps(newProps) {
        if (newProps.errors) {
          this.setState({ errors: newProps.errors });
        }
        if(newProps.notice){
            this.setState({ message: newProps.notice.text });
            setTimeout(() => { this.setState({ message: '' }); }, 3000);
        }
      }
      onSubmit(e) {
        e.preventDefault();
        // let gems ={
        //   name:this.state.name,
        //   no:this.state.no,
        //   weight:this.state.weight,
        //   hardness:this.state.hardness,
        //   color:this.state.color,
        //   refractor_index:this.state.refractor_index,
        //   shape: this.state.shape,
        //   origin:this.state.origin,
        //   photo:this.state.photo
        // }
        const {name,no,weight,hardness,color,refractor_index,shape,origin,photo}=this.state;
        if(isEmpty(name)){
          this.setState(state=>{return  state.errors.name = "Enter the name"})
          return false;
        }else{
          this.setState(state=>{return  state.errors.name = ""})
        }
        if(isEmpty(no)){
          this.setState(state=>{
            return  state.errors.no = "Enter the number"})
          return false;
        }
        else{
          this.setState(state=>{return  state.errors.no = ""})
        }
        if(isEmpty(weight)){
          this.setState(state=>{
            return  state.errors.weight = "Enter the weight"})
          return false;
        }
        else{
          this.setState(state=>{return  state.errors.weight = ""})
        }
        if(isEmpty(hardness)){
          this.setState(state=>{
            return  state.errors.hardness = "Enter the hardness"})
          return false;
        }
        else{
          this.setState(state=>{return  state.errors.hardness = ""})
        }
        if(isEmpty(color)){
          this.setState(state=>{
            return  state.errors.color = "Enter the color"})
          return false;
        }
        else{
          this.setState(state=>{return  state.errors.color = ""})
        }
        if(isEmpty(shape)){
          this.setState(state=>{
            return  state.errors.shape = "Enter the shape"})
          return false;
        }
        else{
          this.setState(state=>{return  state.errors.shape = ""})
        }
        if(isEmpty(refractor_index)){
          this.setState(state=>{
            return  state.errors.refractor_index = "Enter the Refractor Index"})
          return false;
        }
        else{
          this.setState(state=>{return  state.errors.refractor_index = ""})
        }
        if(isEmpty(origin)){
          this.setState(state=>{
            return  state.errors.origin = "Enter the origin"})
          return false;
        }
        else{
          this.setState(state=>{return  state.errors.origin = ""})
        }
        console.log(photo)
        if(isEmpty(photo.name)){
          
          this.setState(state=>{
            return  state.errors.photo = "Enter the photo"})
          return false;
        }
        else{
          this.setState(state=>{return  state.errors.photo = ""})
        }
        let gems = new FormData();
        gems.append('name',this.state.name)
        gems.append('no',this.state.no)
        gems.append('weight',this.state.weight)
        gems.append('hardness',this.state.hardness)
        gems.append('color',this.state.color)
        gems.append('refractor_index',this.state.refractor_index)
        gems.append('shape', this.state.shape);
        gems.append('origin', this.state.origin);
        gems.append('newfile', this.state.photo);
          
      this.props.addGems(gems);
      this.setState({name:'',no:'',photo:'',origin:'',shape:'',refractor_index:'',hardness:'',color:'',weight:'',errors:{}})
          e.target.reset()

        
      }
      onChange(e) {
          if(e.target.name === 'photo'){
              
            this.setState({ [e.target.name]: e.target.files[0] });
          }else{
            this.setState({ [e.target.name]: e.target.value });
          }
          
      }
  render() {
    const {errors}=this.state;
    return (
      <div className="add-gems">
        <h2>Add Gems</h2>
        <form onSubmit={this.onSubmit}>
            <div className="form">
            <TextFieldGroup
                name="name"
                onChange={this.onChange}
                placeholder="Name"
                error={errors.name}
                value={this.state.name}
                label="Name" />
            <TextFieldGroup
                name="no"
                onChange={this.onChange}
                placeholder="Tracking Number"
                value={this.state.no}
                error={errors.no}
                label="Tracking Number" />
            <TextFieldGroup
                name="weight"
                onChange={this.onChange}
                placeholder="Weight"
                error={errors.weight}
                value={this.state.weight}
                label="Weight" />
            <TextFieldGroup
                name="color"
                onChange={this.onChange}
                error={errors.color}
                placeholder="Color"
                value={this.state.color}
                label="Color" />
            <TextFieldGroup
                name="shape"
                onChange={this.onChange}
                placeholder="Shape"
                error={errors.shape}
                value={this.state.shape}
                label="Shape" />
            <TextFieldGroup
                name="origin"
                onChange={this.onChange}
                placeholder="Origin"
                error={errors.origin}
                value={this.state.origin}
                label="Origin" />
            <TextFieldGroup
                name="hardness"
                onChange={this.onChange}
                placeholder="Hardness"
                error={errors.hardness}
                value={this.state.hardness}
                label="Hardness" />
            <TextFieldGroup
                name="refractor_index"
                onChange={this.onChange}
                placeholder="Refractor Index"
                error={errors.refractor_index}
                value={this.state.refractor_index}
                label="Refractor Index" />
                <div className="input">
                    <div className="upload-btn-wrapper">
                        <button className="upload-btn">Upload Image</button>
                        <input type="file" onChange={this.onChange} name="photo" className="form__input input_box file" />
                        <span style={{color:"red",fontSize:'14px'}}>{errors.photo}</span>
                    </div>
                </div>
            

            </div>
            
                <input type="submit" className="btn btn-block" />
        </form>
        
        {
            !isEmpty(this.state.message)? <div className="mes success">{this.state.message}</div>:null
        }
      </div>
    )
  }
}

AddGems.propTypes ={
    addGems: PropTypes.func.isRequired,
    gems:PropTypes.object.isRequired,
  }
  
  const mapStateToProps = (state) => ({
    gems:state.gems,
  })
  
  
  export default connect(mapStateToProps, {addGems})(AddGems);

// export default AddGems
