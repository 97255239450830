import React,{Component} from 'react';
import {  HashRouter  as Router , Route, Switch} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import './app.scss';

import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser } from './actions/authActions';
import { logoutUser } from './actions/authActions';
import Sidebar from './components/layout/Sidebar'
import Login from './components/auth/Login'
import Gems from './components/Gems/Gems'
import AddGems from './components/Gems/AddGems'

import PrivateRoute from './components/common/PrivateRoute'


import Index from './components/Index/Index'

// Check for Token
if(localStorage.jwtToken){
  // Set auth token header auth 
  setAuthToken(localStorage.jwtToken);
  // Decode Token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set User and isAuth
  store.dispatch(setCurrentUser(decoded));

  // Check for expire token
  const currentTime = Date.now() /1000;
  if(decoded.exp < currentTime) {
    // Logout User
    store.dispatch(logoutUser());
    // Clear current Profile
    // store.dispatch(clearCurrentProfile());
    //Redirect to login
    window.location.href ='/login';

  }
}

let content;
if(window.location.hash.includes('admin')){
// content = <AdminLinks />;
content = <div className="admin">  
            {/* <Sidebar toggle={this.state.toggle} /> */}
            <Sidebar />

            <div className="admin-body">
            <Route exact path='/admin/login' component ={Login} /> 
              <Switch>
                  <PrivateRoute exact path='/admin/gems' component ={Gems} /> 
              </Switch>
              <Switch>
                  <PrivateRoute exact path='/admin/gem/add' component ={AddGems} /> 
              </Switch>
            </div>
          </div>;
      
}else{
   content = (
   <div className="content">
    <Index />
  </div>
);

}

class App extends Component  {
  constructor(props) {
    super(props)
  
    this.state = {
       toggle:false
    }
    this.onClick= this.onClick.bind(this)
  }

  onClick(){
    this.setState({toggle:!this.state.toggle})
  }
  render() {
  return (
    <Provider store= {store}>
        <Router basename="/">
          <div className="App">
              {content}
          </div>
        </Router>
      </Provider>
  );
  }
}

export default App;
