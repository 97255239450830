import React, { Component } from 'react'
import { Link ,NavLink,withRouter  } from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {logoutUser} from '../../actions/authActions';

// import logo from '../../img/logo/logoAlps.png'

class Sidebar extends Component {
    
    onLogoutClick(e){
        e.preventDefault();
        // this.props.clearCurrentProfile();
        this.props.logoutUser();
      }
  render() {
    const {isAuthenticated, user} = this.props.auth;
    const guestLinks =(
        <ul className="newSidebar-link">
        <li className="newSidebar-link__item">
          <NavLink   activeClassName='active' to="/admin">
            <i className="material-icons">home</i>
            <span>Dashboard</span>
          </NavLink>
        </li>
        <li className="newSidebar-link__item">
          <NavLink  activeClassName='active' to="/admin/login">
            <i className="material-icons">verified_user</i>
            <span>Login</span>
          </NavLink>
        </li>
        {/* <li className="newSidebar-link__item">
          <NavLink  activeClassName='active' to="/adminregister">
            <i className="material-icons">person_add</i>
            <span>Register</span>
          </NavLink>
        </li> */}
        </ul>
      );
      const authLinks =(
        <ul className="newSidebar-link">
        <NavLink activeClassName='active' to="/admin/home">
          <li className="newSidebar-link__item">
            
              <i className="material-icons">home</i>      
              <span>Dashboard</span>
          </li>
        </NavLink>
        <NavLink   activeClassName='active' to="/admin/gems">
        <li className="newSidebar-link__item">
          
            <i className="material-icons">pie_chart</i>
            <span>Gems</span>
        </li></NavLink>
        <NavLink activeClassName='active' to="/admin/gem/add">
        <li className="newSidebar-link__item">
          
            <i className="material-icons">contacts</i>
            <span>Gems Add</span>
        </li></NavLink>
        <NavLink activeClassName='active' to="/admin/contacts">
        <li className="newSidebar-link__item">
          
            <i className="material-icons">contacts</i>
            <span>Contact</span>
        </li></NavLink>
        
        <li className="newSidebar-link__item">
        <Link to="" onClick={this.onLogoutClick.bind(this)} className="nav-link">
              <i className="material-icons">exit_to_app</i>
              <span>Logout</span>
              </Link>

        </li>
        </ul>
      );
    return (
      <div className={this.props.toggle ? "newSidebar":"newSidebar newSidebar-open"}>
          <div className="newSidebar-logo">
              {/* <img src={logo} alt="" /> */}
              {/* <h3 className="newSidebar-logo__heading">
                  Alpwander Adventure
              </h3> */}
          </div>
          {isAuthenticated ? authLinks : guestLinks}
          {/* <ul className="newSidebar-link">
              <li className="newSidebar-link__item">
                  <i className="fa fa-home"></i>
                  <span>DashBoard</span>
              </li>
          </ul> */}
      </div>
    )
  }
}


Sidebar.propTypes ={
    logoutUser :PropTypes.func.isRequired,
    auth : PropTypes.object.isRequired
  }
  
  const mapStateToProps =(state) =>({
    auth: state.auth 
  });
  
  export default withRouter(connect(mapStateToProps,{logoutUser})(Sidebar));