import React, { Component } from 'react'
import gem from '../../../img/1.jpeg'
import isEmpty from '../../../validations/is-empty'

class Gems extends Component {
  render() {
      let gemsContent;
      const {gems}=this.props
      isEmpty(gems) && isEmpty(this.props.gems.id) || this.props.gems===false?
      gemsContent= (<div className="gems">
                <div className="no-searchd">
                <h2>No Result Found</h2>
              <p>Please enter the tracking exactly as it is written in the card</p>

                </div>
              
          </div>)
          :
    gemsContent= (
            <div className="gems">
            <div className="gems-header">
                <div className="gems-header__name">
                    <span>{gems.name}</span>
                </div>
                <div className="gems-header__no">
                    <span>#{gems.no}</span>
                </div>
            </div>
            <div className="gems-image">
                <img src={gems.photo} alt="Gems"/>
            </div>
            <div className="gems-content">
                <div className="gems-content__item">
                    <span className="title">
                        Weight
                    </span>
                    <span className="name">
                    {gems.weight}
                    </span>
                </div>
                <div className="gems-content__item">
                    <span className="title">
                        Color
                    </span>
                    <span className="name">
                    {gems.color}
                    </span>
                </div>
                <div className="gems-content__item">
                    <span className="title">
                        Shape
                    </span>
                    <span className="name">
                    {gems.shape}
                    </span>
                </div>
                <div className="gems-content__item">
                    <span className="title">
                        Hardness
                    </span>
                    <span className="name">
                    {gems.hardness}
                    </span>
                </div>
                {/* <div className="gems-content__item">
                    <span className="title">
                        Measurement
                    </span>
                    <span className="name">
                        6 x 9 x 12
                    </span>
                </div> */}
                <div className="gems-content__item">
                    <span className="title">
                        Origin
                    </span>
                    <span className="name">
                    {gems.origin}
                    </span>
                </div>
                <div className="gems-content__item">
                    <span className="title">
                        Refractor Index
                    </span>
                    <span className="name">
                    {gems.refractor_index}
                    </span>
                </div>
            </div>
          </div>
          )
          
    return (
        
      gemsContent
    )
  }
}

export default Gems
