import React, { Component } from 'react'
import Contact from './Contents/Contact'
import About from './Contents/About'
import What from './Contents/What'
import Slider from './Contents/Slider'
import Gems from './Contents/Gems'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {getGem} from '../../actions/gemsActions'
import ReactLoading from 'react-loading';
import {FaHome,FaInfoCircle,FaPhone} from 'react-icons/fa'
import {MdContacts} from 'react-icons/md'
import {GoRuby} from 'react-icons/go'

class Index extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       search:'',
       active:'slider',
       isSearched:false,
       searchedData:{},
       isLoading:false
    }
    this.onChange=this.onChange.bind(this);
    this.onClick=this.onClick.bind(this);
    this.onSearch=this.onSearch.bind(this);

  }
  onChange(e){
    this.setState({[e.target.name]:e.target.value});
  }
  onClick(item){
    this.setState({active:item,isSearched:false})
  }
  onSearch(item){
    this.props.getGem(item);
    
    this.setState({isSearched:true,active:'gems'}) 

  }
  render() {
    const {gem,loading}= this.props.gems;
    return (
      <div className="home">
        <div className="contain">
          <header>
            <div className="search">
              <input type="search" name="search" onChange={this.onChange} className="search-input" placeholder="Enter Your Tracking Number Here"  />
              <a onClick={()=>this.onSearch(this.state.search)} className="track-btn">Track</a>
            </div>
          </header>
          <main>
            {
              loading?<div className="loading"><ReactLoading type="spin" color="#222" height={100} width={75} /></div>
              :
              this.state.active ==='slider' && !this.state.isSearched?<Slider />:
              this.state.active ==='contact' && !this.state.isSearched?<Contact />:
              this.state.active ==='about' && !this.state.isSearched?<About />:
              this.state.active ==='what' && !this.state.isSearched?<What />:
              this.state.active==='gems' && this.state.isSearched ?<Gems gems={gem} />:null
              
            }
          </main>
          <footer id="footerList">
            <div className="tabs">
                <div className={this.state.active==='slider'?"tabs-item active":"tabs-item"} onClick={()=>this.onClick('slider')}>
                    <span className="tabs-icon"><FaHome /></span>
                    <span className="tabs-name">Home</span>
                </div>
                <div className={this.state.active==='what'?"tabs-item active":"tabs-item"} onClick={()=>this.onClick('what')}>
                    <span className="tabs-icon"><GoRuby /></span>
                    <span className="tabs-name">Our Services</span>
                </div>
                <div className={this.state.active==='about'?"tabs-item active":"tabs-item"} onClick={()=>this.onClick('about')}>
                    <span className="tabs-icon"><FaInfoCircle /></span>
                    <span className="tabs-name">About Us</span>
                </div>
                <div className={this.state.active==='contact'?"tabs-item active":"tabs-item"}  onClick={()=>this.onClick('contact')}>
                    <span className="tabs-icon"><MdContacts /></span>
                    <span className="tabs-name">Contact Us</span>
                </div>
            </div>
          </footer>
        </div>
      </div>
    )
  }
}

Index.propTypes ={
  getGem: PropTypes.func.isRequired,
  gem:PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  gems:state.gems,
})


export default connect(mapStateToProps, {getGem})(Index);

// export default Index
