export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
// export const GET_PROFILE = 'GET_PROFILE';
// export const PROFILE_LOADING = 'PROFILE_LOADING';
// export const PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND';
// export const CLEAR_CURRENT_PROFILE = 'CLEAR_CURRENT_PROFILE';
// export const GET_PROFILES = 'GET_PROFILES';
export const GEM_LOADING = 'GEM_LOADING';
export const GET_GEMS = 'GET_GEMS';
export const GET_GEM = 'GET_GEM';
export const ADD_GEM = 'ADD_GEM';
export const DELETE_GEM = 'DELETE_GEM';


export const CONTACT_LOADING = 'CONTACT_LOADING';
export const ADD_CONTACT = 'ADD_CONTACT';
export const GET_CONTACT = 'GET_CONTACT';
export const GET_CONTACTS = 'GET_CONTACTS';
export const DELETE_CONTACT = 'DELETE_CONTACT';
