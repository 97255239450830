import React from 'react'
import classnames  from 'classnames';
import PropTypes from 'prop-types'

const TextFieldGroup = ({
    name,
    placeholder,
    value,
    error,
    // info,
    classs,
    type,
    onChange,
    disabled,
    icon,
    label
}) => {
  return (
      <div className="input">
            <div className= "input-field">
                <i className="material-icons prefix">{icon}</i>
                <label class="grey-text" htmlFor={name}>{label}</label>
                <input 
                    type={type} 
                    className={classnames('form-control input-field autocomplete',
                    { 'invalid': error })} 
                    placeholder={placeholder} 
                    value={value} 
                    name={name} 
                    onChange={onChange} 
                    disabled={disabled}
                    error={error} />
                        {/* {info && <small className="form-text text-muted">{info}</small>} */}
                        {error && (<div className="invalid red-text" style={{color:'red',fontSize:'14px'}}>{error}</div>)}
                
            </div>
    </div>
  );
};

TextFieldGroup.propTypes={
    name:PropTypes.string.isRequired,
    placeholder:PropTypes.string,
    value:PropTypes.string.isRequired,
    // info:PropTypes.string,
    error:PropTypes.string,
    type:PropTypes.string.isRequired,
    onChange:PropTypes.func.isRequired,
    disabled:PropTypes.string,
    label:PropTypes.string,
    icon:PropTypes.string,
    class:PropTypes.string,
}

TextFieldGroup.defaultProps={
    type :'text',
    classs:'col s12'
}

export default TextFieldGroup;