import React, { Component } from 'react'

class About extends Component {
  render() {
    return (
      <div className="about">
        <div>
        <h2>About Us</h2>
        <p>We are a jaipur based company for lab testing gems stone. We provide many certificates for diamonds gems stone and other jewellery items.</p>
        <p>Wherever you are in the world, you can rely on our international teams of experts to provide you with specialized business solutions to make your business faster, simpler and more Faithful with our certifications. We partner with you to offer independent services that will help you reduce risk, streamline your processes and operate in a more sustainable manner. You can find out more about how we can help you reach your objectives by viewing our Our Certifications Online.</p>

        </div>
        
      </div>
    )
  }
}

export default About
