import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authActions';
import TextFieldGroup from '../common/TextFieldGroup';

class Login extends Component {
  constructor(){
    super();
    this.state ={
      email:'',
      password:'',
      errors:''
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

  }
  onChange(e){
    this.setState({[e.target.name]:e.target.value});
  }
  componentDidMount(){
    if(this.props.auth.isAuthenticated){
      this.props.history.push('/admin/dashboard');
    }
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.auth.isAuthenticated){
      this.props.history.push('/admin/dashboard');
    }
    if(nextProps.errors){
      this.setState({errors:nextProps.errors});
    }
  }
  onSubmit(e){
    e.preventDefault();

    const userData = {
      email:this.state.email,
      password: this.state.password,
    };
    this.props.loginUser(userData)

  }

  render() {
    const { errors } = this.state;
    return (
      <div className="section-login">
        <div className="login-container">
                  <div className="login-header">
                    <h2 className="login-heading" >Log In</h2>

                  </div>
                    <form className="login-form" onSubmit={this.onSubmit}>
                    <div className="form">
                      <TextFieldGroup 
                        placeholder="email" 
                        name="email"
                        type="email" 
                        className
                        value={this.state.email}
                        onChange={this.onChange}
                        error={errors}
                        // icon ="mail" 
                        label="Email"
                        />
                      <TextFieldGroup 
                        placeholder="Password" 
                        name="password"
                        type="password" 
                        value={this.state.password}
                        onChange={this.onChange}
                        error={errors}
                        // icon="lock" 
                        label="Password"
                        />
                      </div>
                      <input type="submit" value="Login" className="btn btn-block" />

                    </form>
                  </div>

        </div>  
    )
  }
}
 
Login.propTypes ={
  loginUser: PropTypes.func.isRequired,
  auth:PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired 
}

const mapStateToProps = (state) => ({
  auth:state.auth,
  errors:state.errors
})


export default connect(mapStateToProps, {loginUser})(Login);
 