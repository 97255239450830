import { combineReducers } from 'redux';
import authReducer from './authReducer';
import gemsReducer from './gemsReducer';
import contactReducer from './contactReducer';
import errorReducer from './errorReducer';



export default combineReducers({
    auth: authReducer,
    gems: gemsReducer,
    contact: contactReducer,
    errors:errorReducer,
});