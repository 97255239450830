import React, { Component } from 'react'
import dia from '../../../img/icon/diamond.png'
import cert from '../../../img/icon/certificate.png'
import cash from '../../../img/icon/money.png'
import gems from '../../../img/icon/gems.png'


class What extends Component {
  render() {
    return (
      <div className="services">
        <div className="services-item">
          <div className="services-item__icon">
            {/* <i className="material-icons">remove_red_eye</i> */}
            <img src={gems} alt=""/>
          </div>
          <div className="services-item__title">
            <h2>Track Stone Online</h2>
          </div>
          <div className="services-item__content">
            <p>You can track your certified gems online and get more of its details.</p>
          </div>
        </div>
        <div className="services-item">
          <div className="services-item__icon">
            {/* <i className="material-icons">remove_red_eye</i> */}
            <img src={dia} alt=""/>
          </div>
          <div className="services-item__title">
            <h2>Quality Gems</h2>
          </div>
          <div className="services-item__content">
            <p>We sell the best quality and genuine gems. Which comes with a certificate to show the stone is real and not fake</p>
          </div>
        </div>
        <div className="services-item">
          <div className="services-item__icon">
            {/* <i className="material-icons">remove_red_eye</i> */}
            <img src={cash} alt=""/>
          </div>
          <div className="services-item__title">
            <h2>Professional Help</h2>
          </div>
          <div className="services-item__content">
            <p>We provide Professional help for anyone looking to buy gems or are trying to know more about gems stones</p>
          </div>
        </div>
        <div className="services-item">
          <div className="services-item__icon">
            {/* <i className="material-icons">remove_red_eye</i> */}
            <img src={cert} alt=""/>
          </div>
          <div className="services-item__title">
            <h2>Gems Testing</h2>
          </div>
          <div className="services-item__content">
            <p>We do gems lab testing and provide certificate for all the genuine gems. The certificate later can also be used to track your stone online.</p>
          </div>
        </div>
      </div>
    )
  }
}

export default What
