import { GET_ERRORS, SET_CURRENT_USER } from './types';
import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import isEmpty from '../validations/is-empty'

//Register User 
// export const registerUser =(userData,history) => dispatch =>{
//     axios
//     .post('http://ggij.co.in/wp/wp-json/jwt-auth/v1/token', userData)
//     .then(res => history.push('/admin/login'))
//     .catch(err => 
//         dispatch({
//             type: GET_ERRORS,
//             payload: err.response.data 

//         })
//         );
// };

//Login Get User Token
    // axios.post('http://ggij.co.in/wp/wp-json/jwt-auth/v1/token',userData)

export const loginUser =(userData)=>dispatch=>{
    axios.post('https://www.newad.ggij.co.in/api/login',userData)
    .then(res => {
        //Save to local Storage 
        if(res.data.error){
            dispatch({
                type: GET_ERRORS,
                payload: res.data.message
    
            });
        }else{
            const { token } = res.data;
            //Set token to ls
            if(!isEmpty(token)){
                localStorage.setItem('jwtToken', token);
                //set token to Auth header 
                setAuthToken(token);
                //Decode token to get user data
                const decode = jwt_decode(token);
                //Set Current user 
                dispatch(setCurrentUser(decode));
            }
            else{
                dispatch({
                    type: GET_ERRORS,
                    payload: res.data 
        
                });
            }

        }
        
        
    })
    .catch(err =>{
        dispatch({
            type: GET_ERRORS,
            payload: err 

        });
    })
};

//Set logged in user 
export const setCurrentUser = (decode) =>{
    return{
        type: SET_CURRENT_USER,
        payload :decode
    }
}


//Log user out 
export const logoutUser = () => dispatch =>{ 
    // Remove the token from local storage
    localStorage.removeItem('jwtToken');
    // Remove auth header for future requests
    setAuthToken(false);
    // Set current user to {} which wil set isAuthen to false
    dispatch(setCurrentUser({}));
}